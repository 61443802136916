import initBannerCarouselData from "src/lib/initBannerCarouselRedirection";
import SpinWheel from "src/lib/spinWheel";

import adInit from "./advertise";
import initMarquee from "./marquee";
import initSectionActiveDetection from "./sectionActiveDetection";
import SwipeCaptcha from "./swipeCaptcha";
import { translate } from "./translate";
import initTweenValue from "./tweenValue";

export default class MemberSite {
  $: any;
  QRCode: any;
  api: any;
  jQueryUI: any;
  isCaptchaEnable: boolean;

  constructor({ $, QRCode, api, jQueryUI }) {
    this.$ = $;
    this.QRCode = QRCode;
    this.api = api;
    this.jQueryUI = jQueryUI;
    this.isCaptchaEnable = false;
    this.init();
    initTweenValue($);
    initSectionActiveDetection($);
  }

  maskMobileNumber = (mobile) => {
    if (mobile === "" || !mobile) return;

    let mask = mobile.substring(3, mobile.length - 3).replace(/\d/g, "*");

    return mobile.substring(0, 3) + mask + mobile.substring(mobile.length - 3);
  };

  /**
   * CREATE COOKIE
   */
  createCookie = (name, value, days?) => {
    let expires;
    if (days) {
      const date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    } else {
      expires = "";
    }
    document.cookie = name + "=" + value + expires + "; path=/";
  };

  /**
   * DETECT PCAPP
   */
  isPCAppElectron = () => {
    return window.navigator.userAgent.toLowerCase().indexOf("electron") !== -1;
  };

  /** Generate 联系客服 dropdown */
  generateContactKefuDropdown = (contactKefu?: { icon: string; name: string; label: string }[]) => {
    const { $ } = this;
    const hasContactNames = contactKefu.some(({ name }) => name);

    if (!hasContactNames) {
      $('[api-link="contact-kefu"]').hide();
      return;
    }

    const contactDropdownElement = $(`<div class="contact-dropdown">
      ${contactKefu
        .map(({ icon, name, label }) => {
          if (name) {
            return `<div class="flex flex-space-between contact-item"><img class="icon-contact" src="${icon}" /><div class="contact-wrapper">${
              window.themeName.includes("dsn") ? `<p class="contact-label">${label}</p>` : ""
            } <p class="contact-name">${name}</p></div><img src="img/copy.svg" class="icon-copy"/></div>`;
          }
        })
        .join("")}
    </div>`);

    $('[api-link="contact-kefu-list"]').append(contactDropdownElement);

    const copyToClipboard = function (currentButton) {
      navigator.clipboard.writeText(currentButton.siblings("p").text());
      const currentContactItem = currentButton.parent();

      currentContactItem.append(`<p class='copy'>${translate("已复制")}</p>`);
      currentContactItem.find(".icon-copy").remove();

      setTimeout(function () {
        currentContactItem.find(".copy").remove();
        currentContactItem.append("<img class='icon-copy' src='img/copy.svg'/>");
        currentContactItem.find(".icon-copy").on("click", function () {
          copyToClipboard($(this));
        });
      }, 2000);
    };

    $(".icon-copy").on("click", function () {
      copyToClipboard($(this));
    });
  };

  /** Generate 联系客服 accordion */
  generateContactKefuAccordion = (contactKefu?: { icon: string; name: string; label: string; qrcode?: string }[]) => {
    const { $ } = this;
    const hasContactNames = contactKefu.some(({ name }) => name);

    if (!hasContactNames) {
      $('[api-link="contact-kefu"]').hide();
      return;
    }

    const contactDropdownElement = $(`<div class="contact-accordion-dropdown">
        ${contactKefu
          .map(({ icon, name, label, qrcode }) => {
            if (name) {
              return `<div class="flex flex-col contact-item-accordion">
                        <div class="flex contact-item-accordion-button">
                          <img class="icon-contact" src="${icon}" />
                          <p class="contact-label">${label}</p>
                          <img class="icon-caret" src="img/caretIcon.svg" />
                        </div>
                        <div style="display: none;">
                          ${
                            !!qrcode
                              ? `<div class="contact-item-accordion-qrcode-wrapper">
                                  <img src="${qrcode}" class="qrcode" />
                                </div>
                                `
                              : ""
                          }
                          <div class="contact-item-accordion-copy-button flex">
                            <p class="contact-name">${name}</p>
                            <img src="img/copy.png" class="icon-copy" />
                          </div>
                        </div>
                      </div>`;
            }
          })
          .join("")}
      </div>`);

    $('[api-link="contact-kefu-accordion"]').append(contactDropdownElement);

    const copyToClipboard = function (currentButton) {
      if (!currentButton.find("p").text() === translate("已复制")) {
        return;
      }

      const currentText = currentButton.find("p").text();
      navigator.clipboard.writeText(currentButton.find("p").text());

      currentButton.empty();
      currentButton.append(
        `<p class="contact-name">${translate("已复制")}</p>
        <img src="img/copy.png" class="icon-copy"/>
        `
      );

      setTimeout(function () {
        currentButton.empty();
        currentButton.append(
          `<p class="contact-name">${currentText}</p>
          <img src="img/copy.png" class="icon-copy"/>
          `
        );
      }, 2000);
    };

    $(".contact-item-accordion-copy-button").on("click", function () {
      copyToClipboard($(this));
    });

    $(".contact-item-accordion-button").on("click", function () {
      $(this).next().toggle(200);

      if ($(this).find(".icon-caret").hasClass("active")) {
        $(this).find(".icon-caret").removeClass("active");
      } else {
        $(this).find(".icon-caret").addClass("active");
      }
    });
  };

  /**
   * INIT APPLYING
   */
  init = () => {
    const {
      $,
      api,
      applyThemeColor,
      detectGuestButton,
      applyBannerSrc,
      applyConfig,
      getCaptchaEnable,
      bindAPI,
      renderQrCode,
      appendGAScript,
      passwordReveal,
      registerSendOtp,
      generateCustomerServiceDropdown,
    } = this;

    const that = this;
    const isNewMemberPage = !!$("body[api-member-page='new']").length;

    var adStructure = $(`<div class="landing-advertise" api-boolean="user.webMain.advert">
      <div class="ad-bg"></div>
      <div class="ad-frame">
        <div class="btn-close-bg"></div>
        <div class="btn-close"></div>
        <div class="ad-image"><img src="" api-img="user.webMain.advert" /></div>
        <div class="ad-countdown"><span data-counter="5">-</span><span>&nbsp${translate("秒内关闭")}</span></div>
      </div>
    </div>`);

    if (location.pathname === "/home/") {
      $("body").append(adStructure);
    }

    let affId = this.readCookie("affid");
    let aff = this.getQueryVariable("aff");
    if (aff) {
      affId = aff;
    }
    this.createCookie("affid", affId);

    window.affCode = this.getQueryVariable("a");
    if (window.affCode) {
      this.createCookie("affCode", window.affCode);
    } else {
      window.affCode = this.readCookie("affCode");
    }

    // 如果网页是最上层才call checkLogin
    if (window.top === window) {
      api.checkLogin("", function (response) {
        if (response.data) {
          var data = response.data.data;

          window.isGuest = data.tesing;
          window.userType = data.userType;

          $(".before-login").addClass("hide");
          $(".after-login").removeClass("hide");

          $("a.game-link").removeClass("guestLoginBtn").attr("href", "/player/index");

          $(".guestLoginBtn").remove();
          if (data.userType === 0 || data.tesing === 1) {
            $("#personalCenter").remove();
            $("#financialManagement").remove();
          }

          // username visibility for dsn
          let isUsernameVisible = localStorage.getItem("usernameVisible") === "true";

          const updateUsername = () => {
            let username = isUsernameVisible ? data.userName : `${data.userName.slice(0, 3)}***`;

            if (data.userType === 0 && data.tesing === 1) {
              $("#loginUsername").text(translate("游客"));
              $("#usernameVisibleBtn").remove();
            } else if (data.userType === 1 && data.tesing === 1) {
              $("#loginUsername").html(`(<span class="translation">${translate("试玩")}</span>) ${username}`);
            } else {
              $("#loginUsername").text(username);
            }
          };

          const updateUsernameIcon = () => {
            const usernameVisibleIcon = $("#usernameVisibleBtn").find("#usernameVisibleIcon");

            let iconSrc = isUsernameVisible ? "img/eye_close.png" : "img/eye_open.png";
            usernameVisibleIcon.attr("src", iconSrc);
          };

          updateUsername();
          updateUsernameIcon();

          $("#usernameVisibleBtn").click((e) => {
            e.preventDefault();
            isUsernameVisible = !isUsernameVisible;
            updateUsername();
            updateUsernameIcon();
            localStorage.setItem("usernameVisible", isUsernameVisible.toString());
          });

          api.getLoggedInPromo(function (response) {
            if (response) {
              const data = response.data.result;
              window.promo = data;
            }
          });

          // if logged in, check for spinWheelEnable & sys.spinwheel.enabled for spin wheel
          api.getUserInfo(function (response) {
            if (response) {
              const {
                spinWheelEnable,
                changePassword,
                remindChangePassword,
                remindChangePasswordMsg,
                seqAnsPasswordType,
                status,
              } = response.data.result || {};
              window.userStatus = status;

              if (changePassword || remindChangePassword) {
                const changePasswordEvent = new CustomEvent("changePassword", {
                  detail: {
                    data: {
                      changePassword,
                      remindChangePassword,
                      remindChangePasswordMsg,
                      seqAnsPasswordType,
                    },
                  },
                });

                window.dispatchEvent(changePasswordEvent);
              }

              api.getMbConfigs(function (response) {
                if (response) {
                  const data = response.data.result;
                  const isSpinwheelEnabled = data["sys.spinwheel.enabled"];

                  SpinWheel({
                    iSpinWheelAvailable: isSpinwheelEnabled && spinWheelEnable,
                    isLoggedIn: true,
                  });
                }
              });
            }
          });
        } else {
          api.getPromo(function (response) {
            if (response) {
              const data = response.data.result;
              window.promo = data;
            }
          });

          api.getMbConfigs(function (response) {
            if (response) {
              const data = response.data.result;
              const isSpinwheelEnabled = data["sys.spinwheel.enabled"];

              SpinWheel({
                iSpinWheelAvailable: isSpinwheelEnabled,
                isLoggedIn: false,
              });
            }
          });
        }
      });
    }
    ``;

    api.getMbConfigs(function (response) {
      if (response) {
        const data = response.data.result;
        const affKey = data["config.affKey"];
        const affValue = data["config.aff"];
        const userOnlineServiceUrls = data["user.online.service.url"];

        const contactKefuItems = [
          {
            icon: "img/icon-wechat.svg",
            name: data["online.support.wechat.acc"],
            label: "Wechat",
            qrcode: data["online.support.wechat"],
          },
          {
            icon: "img/icon-hushchat.svg",
            name: data["user.mb.service.qq"],
            label: "HushChat",
            qrcode: data["online.support.qq"],
          },
          { icon: "img/icon-skype.svg", name: data["user.web.service.skype"], label: "Skype" },
          {
            icon: "img/icon-morse.svg",
            name: data["app.morse.member"],
            label: "Morse",
            qrcode: data["app.morse.member"],
          },
          { icon: "img/icon-contact-email.svg", name: data["user.web.service.mail"], label: "Email" },
        ];

        if (
          !data["online.support.qq.qrcode"] &&
          !data["online.support.wechat"] &&
          !data["app.morse.number"] &&
          !data["user.online.service.url"] &&
          !data["user.support.app"]
        ) {
          $(".kefu-content").hide();
        }

        const isCaptchaEnable = getCaptchaEnable(data);

        const banners =
          data.banners && data.banners.length !== 0
            ? data.banners.filter((item) => {
                const { imageUrl, aff } = item;
                return imageUrl && aff.includes(affValue);
              })
            : [];

        window.themeName = data["theme.name"];

        that.isCaptchaEnable = isCaptchaEnable;

        detectGuestButton(data);
        applyThemeColor(data);
        applyBannerSrc(data);
        applyConfig(data);
        that.generateContactKefuDropdown(contactKefuItems);
        that.generateContactKefuAccordion(contactKefuItems);

        // bind api and generate verification images functions after applying configs
        bindAPI();
        renderQrCode($(".qr-code"));
        appendGAScript(data);

        if (userOnlineServiceUrls) {
          generateCustomerServiceDropdown(userOnlineServiceUrls);
        }

        // after config filter show whole page
        $("body").addClass("ready");

        window.applyConfig = function ({ branding, config, action, qr }) {
          if (branding) {
            applyBannerSrc(data);
          }
          if (config) {
            applyConfig(data);
          }
          if (action) {
            bindAPI();
          }
          if (qr) {
            renderQrCode($(".qr-code"));
          }
        };

        window.banner = banners;

        const csUrl = userOnlineServiceUrls && userOnlineServiceUrls[0] && userOnlineServiceUrls[0].url;
        window.initBannerCarouselRedirection = initBannerCarouselData({
          appDownloadUrl: data["app.download.member"],
          csUrl,
        });

        adInit($);
        passwordReveal();
        registerSendOtp();
      }
    });

    if ($(".marquee").length) {
      api.getNotice(function (response) {
        if (response) {
          const data = response.data.result;
          let notice = "";
          data.forEach(function (value, key) {
            notice += value.text + " ";
          });
          $(".marquee").text(notice);
          initMarquee($);
        }
      });
    }
  };

  LightenDarkenColor = (col, amt) => {
    var usePound = false;
    if (col[0] == "#") {
      col = col.slice(1);
      usePound = true;
    }

    var num = parseInt(col, 16);

    var r = (num >> 16) + amt;

    if (r > 255) r = 255;
    else if (r < 0) r = 0;

    var b = ((num >> 8) & 0x00ff) + amt;

    if (b > 255) b = 255;
    else if (b < 0) b = 0;

    var g = (num & 0x0000ff) + amt;

    if (g > 255) g = 255;
    else if (g < 0) g = 0;

    return (usePound ? "#" : "") + (g | (b << 8) | (r << 16)).toString(16);
  };

  shadeColor = (color, percent) => {
    let R = parseInt(color.substring(1, 3), 16);
    let G = parseInt(color.substring(3, 5), 16);
    let B = parseInt(color.substring(5, 7), 16);

    R = parseInt(((R * (100 + percent)) / 100).toString());
    G = parseInt(((G * (100 + percent)) / 100).toString());
    B = parseInt(((B * (100 + percent)) / 100).toString());

    R = R < 255 ? R : 255;
    G = G < 255 ? G : 255;
    B = B < 255 ? B : 255;

    var RR = R.toString(16).length == 1 ? "0" + R.toString(16) : R.toString(16);
    var GG = G.toString(16).length == 1 ? "0" + G.toString(16) : G.toString(16);
    var BB = B.toString(16).length == 1 ? "0" + B.toString(16) : B.toString(16);

    return "#" + RR + GG + BB;
  };

  hexToRGB = (hex, alpha) => {
    var r = parseInt(hex.slice(1, 3), 16),
      g = parseInt(hex.slice(3, 5), 16),
      b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
    } else {
      return "rgb(" + r + ", " + g + ", " + b + ")";
    }
  };

  /**
   * Detect Guest Button
   */
  detectGuestButton = (data) => {
    const affKey = data["config.affKey"];
    const affValue = data["config.aff"];

    const ESiteTypes = [
      0, // official
      1, // ydl
      2, // wdl
    ];

    const keyMap = ["login.guest.enable", "login.guest.activity.enable", "login.guest.noactivity.enable"];

    let siteType;

    if (affKey != "1") {
      //Official
      siteType = ESiteTypes[0];
    } else {
      if (affValue == "2") {
        //YDL
        siteType = ESiteTypes[1];
      } else if (affValue == "1") {
        //WDL
        siteType = ESiteTypes[2];
      }
    }

    /**
     * Guest login will be check at API side
     */
    if (data[keyMap[siteType]] !== "true") {
      // $(".guestLoginBtn").remove();
    }
  };

  /**
   * APPLY THEME COLOR
   */
  applyThemeColor = (data) => {
    const primaryColor = data["express.theme.color"];
    const secondaryColor = data["express.theme.color.secondary"];

    if (primaryColor) {
      const primaryLightColor = this.shadeColor(primaryColor, 42); // 42% Lighter to the primaryColor
      const primaryDarkColor = this.shadeColor(primaryColor, -40); // 40% Darker to the primaryColor
      const transparentPrimaryColor = this.hexToRGB(primaryColor, 0.6);

      document.documentElement.style.setProperty("--primaryColor", primaryColor);
      document.documentElement.style.setProperty("--primaryLightColor", primaryLightColor);
      document.documentElement.style.setProperty("--primaryDarkColor", primaryDarkColor);
      document.documentElement.style.setProperty("--transparentPrimaryColor", transparentPrimaryColor);

      //@ts-ignore
      window.globalColor = {
        primaryColor: primaryColor,
        primaryLightColor: primaryLightColor,
        primaryDarkColor: primaryDarkColor,
        transparentPrimaryColor: transparentPrimaryColor,
      };
    }

    if (secondaryColor) {
      const secondaryLightColor = this.shadeColor(secondaryColor, 42); // 42% Lighter to the secondaryColor
      const secondaryDarkColor = this.shadeColor(secondaryColor, -40); // 40% Darker to the secondaryColor
      const transparentSecondaryColor = this.hexToRGB(secondaryColor, 0.6);

      document.documentElement.style.setProperty("--secondaryColor", secondaryColor);
      document.documentElement.style.setProperty("--secondaryLightColor", secondaryLightColor);
      document.documentElement.style.setProperty("--secondaryDarkColor", secondaryDarkColor);
      document.documentElement.style.setProperty("--transparentSecondaryColor", transparentSecondaryColor);

      //@ts-ignore
      window.globalColor = {
        secondaryColor: secondaryColor,
        secondaryLightColor: secondaryLightColor,
        secondaryDarkColor: secondaryDarkColor,
        transparentSecondaryColor: transparentSecondaryColor,
      };
    }
  };

  /**
   * APPLY BANNER SRC
   */
  applyBannerSrc = (data) => {
    const { $ } = this;
    var types = {
      official: 0,
      ydl: 1,
      wdl: 2,
    };
    var currentType = null;
    if (data["config.affKey"] != "1") {
      //Official
      currentType = types["official"];
      $("[aff-only]").remove();
      // All shows
    } else {
      if (data["config.aff"] == "2") {
        //YDL
        currentType = types["ydl"];
        $("#affLink").remove();
        $("[official-only]").remove();
        $("[aff-banner-src]").each(function () {
          replaceSrc($(this), "_ydl");
        });
        $("[aff-class]").each(function () {
          $(this).addClass("ydl");
        });
        // changeFavicon("favicon_ydl.ico");
      } else if (data["config.aff"] == "1") {
        // WDL

        if (window.themeName.includes("dsn")) {
          data["base.webname"] = translate("彩乐园");
          data["base.web.title"] = translate("彩乐园");
        } else {
          data["base.webname"] = translate("彩娱乐");
          data["base.web.title"] = translate("彩娱乐");
        }

        currentType = types["wdl"];
        $("#affLink").remove();
        $("[official-only]").remove();
        $("[aff-banner-src],[aff-faq-src]").each(function () {
          replaceSrc($(this), "_wdl");
        });
        $("[aff-class]").each(function () {
          $(this).addClass("wdl");
        });
        // changeFavicon("img/favicon_wdl.ico");
        changeFaviconUrl("a");
      }
    }

    if (currentType !== types["official"]) $("[official-only]").remove();
    if (currentType !== types["wdl"]) $("[wdl-only]").remove();
    if (currentType !== types["ydl"]) $("[ydl-only]").remove();

    $("[api-show-with]").each(function (i, elem) {
      var typeFilter = $(elem).attr("api-show-with");
      switch (currentType) {
        case types["official"]:
          if (typeFilter.indexOf("official") == -1) $(elem).remove();
          break;
        case types["ydl"]:
          if (typeFilter.indexOf("ydl") == -1) $(elem).remove();
          break;
        case types["wdl"]:
          if (typeFilter.indexOf("wdl") == -1) $(elem).remove();
          break;
      }
    });

    function replaceSrc(selector, replacement) {
      var originalFullSrc = selector.attr("src").split(".");
      var originalSrc = originalFullSrc[0];
      var extension = originalFullSrc[1];
      selector.attr("src", originalSrc + replacement + "." + extension);
    }

    function changeFavicon(src) {
      var link = document.createElement("link"),
        oldLink = document.getElementById("dynamic-favicon");
      link.id = "dynamic-favicon";
      link.rel = "shortcut icon";
      link.href = src;
      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    }

    function changeFaviconUrl(replacement) {
      var link = document.createElement("link"),
        oldLink = document.getElementById("dynamic-favicon") as HTMLLinkElement,
        extension,
        originalName,
        urlLink,
        filename,
        newUrl;

      urlLink = oldLink.href;
      originalName = urlLink.lastIndexOf("/");
      extension = urlLink.lastIndexOf(".");
      filename = urlLink.substring(urlLink.lastIndexOf("/") + 1, extension);
      newUrl = urlLink.substring(0, originalName + 1) + filename + replacement + urlLink.substring(extension);

      link.id = "dynamic-favicon";
      link.rel = "shortcut icon";
      link.href = newUrl;

      if (oldLink) {
        document.head.removeChild(oldLink);
      }
      document.head.appendChild(link);
    }
  };

  /**
   * GET FILE EXTENSION
   */
  getFileExtension = (filename) => {
    return filename.split(".").pop();
  };

  /**
   * GET CAPTCHA
   */
  getCaptchaEnable = (data) => data["member.login.code.enable"] === "true";
  /**
   * APPLY CONFIGS
   */
  applyConfig = (data) => {
    const { $ } = this;

    function isValidUrl(string) {
      try {
        new URL(string);
        return true;
      } catch {
        return false;
      }
    }

    $("[api-boolean]").each(function (i, elem) {
      const flagName = $(elem).attr("api-boolean");
      const content = data[flagName];

      if (content === "false" || !content || ($(elem).hasClass("affLoginUrl") && !isValidUrl(content))) {
        $(elem).remove();
      }
    });

    $("[api-online-service]").each(function (i, elem) {
      const flagName = $(elem).attr("api-online-service");
      const [flagKey, flagIndex] = flagName.split("[");
      const numberFlagIndex = Number(flagIndex.slice(0, -1));

      const targetData = data[flagKey] || [];
      const { title, url } = targetData[numberFlagIndex] || {};

      if (!url) {
        $(elem).remove();
      } else {
        $(elem).attr("href", url);
        $(elem).text(title);
      }
    });

    $("[api-string]").each(function (i, elem) {
      const flagName = $(elem).attr("api-string");
      $(elem).text(data[flagName]);
    });

    $("[api-string-link]").each(function (i, elem) {
      const flagName = $(elem).attr("api-string-link");
      const content = data[flagName];

      if (isValidUrl(content)) {
        $(elem).html(`<a class="color-link" href="${content}" target="_blank">点此联系</a>`);
      } else {
        $(elem).text(content);
      }
    });

    $("[api-link]").each(function (i, elem) {
      const flagName = $(elem).attr("api-link");
      $(elem).attr("href", data[flagName]);
    });

    $("[api-mail]").each(function (i, elem) {
      const flagName = $(elem).attr("api-mail");
      $(elem).attr("href", "mailto:" + data[flagName]);
    });

    $("[api-img]").each(function (i, elem) {
      const flagName = $(elem).attr("api-img");
      $(elem).attr("src", data[flagName]);
    });

    $("[api-qrcode]").each(function (i, elem) {
      const flagName = $(elem).attr("api-qrcode");
      $(elem).attr("data-qr-url", data[flagName]);
    });

    $("[api-qq]").each((i, elem) => {
      const flagName = $(elem).attr("api-qq");
      if (!data[flagName]) return false;
      let qqSource = "";
      // Determine its an image or a link, accepted jpg, png and gif extension
      if (
        this.getFileExtension(data[flagName]).includes("jpg") ||
        this.getFileExtension(data[flagName]).includes("png") ||
        this.getFileExtension(data[flagName]).includes("gif")
      ) {
        qqSource = data[flagName];
        $(elem).attr("href", "#!");

        // Extra checking for data-qq-image attr
        if ($(elem).find("[data-qq-image]").length !== 0) {
          $(elem).find("[data-qq-image]").attr("src", qqSource);
        } else {
          $(elem).attr("href", qqSource).attr("target", "_blank");
        }
      } else {
        if (data[flagName].indexOf("wpa.qq.com") !== -1) {
          qqSource = data[flagName];
        } else {
          qqSource = `http://wpa.qq.com/msgrd?v=3&uin=${data[flagName]}&site=web&menu=yes`;
        }
        $(elem).find("[data-qq-image-wrapper]").remove();
        $(elem).find("[data-qq-image]").remove();
        $(elem).attr("href", qqSource).attr("target", "_blank");
      }
    });

    $("[api-qr-middleware]").each(function (i, elem) {
      const flagName = $(elem).attr("api-qr-middleware");
      $(elem).attr("data-qr-middleware", data[flagName]);
    });
  };

  /**
   * PUZZLE CAPTCHA
   */
  getPuzzleCaptcha = (enable) => {
    const { alertBox } = window;
    return new Promise<{ cryptograph: string; code: string }>((resolve) => {
      SwipeCaptcha({
        selector: document.body,
        successCallback: ({ cryptograph, code }) => {
          if (code == "") return alertBox({ message: translate("请重新验证"), type: "info", confirmFunc: enable });
          resolve({ cryptograph, code });
        },
        closeCallback: () => {
          return;
        },
      });
    });
  };

  /** Multiple Customer Service URL*/
  generateCustomerServiceDropdown = (userOnlineServiceUrls: { title: string; url: string }[]) => {
    let isMouseEnterElement = false;
    let hoverTimeoutID;
    const { $ } = this;
    const offset = 8;
    const userOnlineServiceUrlElement = $('[api-link="user.online.service.url"]');
    const commonDropdownElement = $(`<div class="user-online-service-url-dropdown-wrapper">
      <div class="user-online-service-url-dropdown-wrapper__arrow"></div>
      ${userOnlineServiceUrls
        .map(
          ({ title, url }) =>
            `<a href="${url}" target="_blank" class="user-online-service-url-dropdown-wrapper__url">${title}</a>`
        )
        .join("")}
    </div>`);

    $("body").append(commonDropdownElement);

    function getViewportOffset($e) {
      const $window = $(window),
        scrollLeft = $window.scrollLeft(),
        scrollTop = $window.scrollTop(),
        offset = $e.offset(),
        rect1 = { x1: scrollLeft, y1: scrollTop, x2: scrollLeft + $window.width(), y2: scrollTop + $window.height() },
        rect2 = { x1: offset.left, y1: offset.top, x2: offset.left + $e.width(), y2: offset.top + $e.height() };
      return {
        left: offset.left - scrollLeft,
        top: offset.top - scrollTop,
        insideViewport: rect1.x1 < rect2.x2 && rect1.x2 > rect2.x1 && rect1.y1 < rect2.y2 && rect1.y2 > rect2.y1,
      };
    }

    function computeOffsetLeft(element, direction) {
      const { left } = getViewportOffset(element);
      const dropdownElement = $(".user-online-service-url-dropdown-wrapper");
      return direction === "bottom"
        ? left + element.outerWidth() - dropdownElement.outerWidth()
        : left - dropdownElement.outerWidth() - offset;
    }

    function computeOffsetTop(element, direction) {
      const { top } = getViewportOffset(element);
      const dropdownElement = $(".user-online-service-url-dropdown-wrapper");
      return direction === "bottom"
        ? top + element.outerHeight() + offset
        : top - dropdownElement.outerHeight() / 2 + element.outerHeight() / 2;
    }

    userOnlineServiceUrlElement.click(function (e) {
      e.preventDefault();
    });

    userOnlineServiceUrlElement.each(function (e) {
      $(this).attr("href", "#!");

      $(this).mouseenter(function (e) {
        clearTimeout(hoverTimeoutID);
        const direction = $(this).data("dropdown-direction") || "bottom";
        const left = computeOffsetLeft($(this), direction);
        const top = computeOffsetTop($(this), direction);
        $(".user-online-service-url-dropdown-wrapper")
          .removeClass("top")
          .removeClass("right")
          .removeClass("bottom")
          .removeClass("left")
          .addClass(direction)
          .addClass("active")
          .css("top", top)
          .css("left", left);

        if (direction === "bottom") {
          const arrowLeft = $(this).outerWidth() / 2 - 6;
          $(".user-online-service-url-dropdown-wrapper .user-online-service-url-dropdown-wrapper__arrow").css(
            "right",
            arrowLeft
          );
        }
      });

      $(this).mouseleave(function (e) {
        hoverTimeoutID = setTimeout(function () {
          if (!isMouseEnterElement) {
            $(".user-online-service-url-dropdown-wrapper").removeClass("active");
          }
        }, 100);
      });
    });

    $(".user-online-service-url-dropdown-wrapper")
      .mouseenter(function () {
        isMouseEnterElement = true;
      })
      .mouseleave(function (e) {
        if (isMouseEnterElement) {
          $(this).removeClass("active");
          isMouseEnterElement = false;
        }
      });

    $(".user-online-service-url-dropdown-wrapper .user-online-service-url-dropdown-wrapper__url").click(function (
      event
    ) {
      event.preventDefault();
      const url = $(this).attr("href");
      window.open(url, "newwindow", "width=400, height=600,resizable=yes,scrollbars=yes");
    });

    // Append custom style to the head
    $("head").append(`<style>
      .user-online-service-url-dropdown-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        padding: 12px;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.2);
        border-radius: 8px;
        opacity: 0;
        visibility: hidden;
        z-index: 1000;
        transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1), visibility 200ms cubic-bezier(0.4, 0, 0.2, 1),
          transform 200ms cubic-bezier(0.4, 0, 0.2, 1);
      }

      .user-online-service-url-dropdown-wrapper.active {
        opacity: 1;
        visibility: visible;
        transform: translate(0, 0) !important;
      }

      .user-online-service-url-dropdown-wrapper .user-online-service-url-dropdown-wrapper__arrow {
        border: 6px solid transparent;
        width: 0;
        height: 0;
        position: absolute;
      }

      .user-online-service-url-dropdown-wrapper.bottom {
        transform: translate(0, 10px);
      }

      .user-online-service-url-dropdown-wrapper.bottom .user-online-service-url-dropdown-wrapper__arrow {
        border-bottom: 6px solid #fff;
        top: -12px;
        right: 0;
      }

      .user-online-service-url-dropdown-wrapper.left {
        transform: translate(-10px, 0);
      }

      .user-online-service-url-dropdown-wrapper.left .user-online-service-url-dropdown-wrapper__arrow {
        border-left: 6px solid #fff;
        top: 50%;
        transform: translateY(-50%);
        right: -12px !important;
      }

      .user-online-service-url-dropdown-wrapper .user-online-service-url-dropdown-wrapper__url {
        display: block;
        padding: 8px 12px;
        border-radius: 6px;
        color: #333;
        text-decoration: none;
        white-space: nowrap;
        position: relative;
      }

      .user-online-service-url-dropdown-wrapper .user-online-service-url-dropdown-wrapper__url:hover {
        background-color: #eee;
      }

      .user-online-service-url-dropdown-wrapper .user-online-service-url-dropdown-wrapper__url:not(:last-child) {
        border-bottom: 1px solid #eee;
      }
    </style>`);
  };

  /** Popup Agreement Page */
  popupAgreementPage = (urlPayload?: string) => {
    var iframe = document.createElement("iframe");

    iframe.className = "iframe-agreement";
    iframe.id = "agreement-frame";
    iframe.src = "/player/agreement" + (urlPayload ? `?${urlPayload}` : "");

    document.body.appendChild(iframe);
  };

  doLogin = ({ username, password, code, cryptograph, enable }) => {
    const { $, api } = this;
    const { alertBox } = window;
    const isNewMemberPage = !!$("body[api-member-page='new']").length;

    api.login(
      {
        username,
        password,
        code,
        cryptograph,
      },
      (response) => {
        if (response.data && response.data.data && response.data.data.otpRequired) {
          const { mobile = "", id = "", username = "" } = response.data && response.data.data ? response.data.data : {};
          this.checkOTP({ mobile, username, userpassid: id, isReg: false });
          return;
        }

        // OTP not required
        if (response.data.success && response.data.message) {
          this.popupAgreementPage(response.data.message);
        } else {
          alertBox({ message: response.data.message, type: "error", confirmFunc: enable });
        }
      }
    );
  };

  /**
   * API BINDING
   */
  bindAPI = () => {
    const { $, api, isCaptchaEnable, maskMobileNumber } = this;
    const { alertBox } = window;
    const isNewMemberPage = !!$("body[api-member-page='new']").length;

    //! login
    {
      $("#loginForm input").keydown(function (e) {
        if (e.keyCode === 13) {
          e.preventDefault();
          $("#loginBtn").click();
        }
      });

      $("#loginBtn").click(async (event) => {
        event.preventDefault();
        const button = this;
        $(button).prop("disabled", true);
        const enable = () => $(button).prop("disabled", false);
        const form = $("#loginForm");

        let loginData = {
          username: "",
          password: "",
          code: "",
          cryptograph: "",
        };

        loginData.username = form.find('[name="username"]').val();
        loginData.password = form.find('[name="password"]').val();

        if (loginData.username == "")
          return alertBox({ message: translate("请输入账号"), type: "error", confirmFunc: enable });
        if (loginData.password == "")
          return alertBox({ message: translate("请输入密码"), type: "error", confirmFunc: enable });

        if (isCaptchaEnable) {
          const { cryptograph, code } = await this.getPuzzleCaptcha(enable);

          loginData.cryptograph = cryptograph;
          loginData.code = code;
        } else {
          delete loginData.code;
          delete loginData.cryptograph;
        }

        this.doLogin({
          ...loginData,
          enable,
        });
      });

      $("#guestLoginBtn, .guestLoginBtn").click(function () {
        var that = this;
        $(this).prop("disabled", true);
        alertBox({
          message: translate("游客盘口只供试玩，与正式会员盘口无关!"),
          type: "info",
          confirmFunc: function () {
            api.login(
              {
                username: "!guest!",
                password: "!guest!",
              },
              function (response) {
                if (response.data.success) {
                  var iframe = document.createElement("iframe");

                  iframe.className = "iframe-agreement";
                  iframe.id = "agreement-frame";
                  iframe.src = `/player/agreement?${response.data.message}`;

                  document.body.appendChild(iframe);

                  var queryElement = document.getElementById("agreement-frame");

                  queryElement.addEventListener("load", () => {
                    var base = document.createElement("base");
                    base.setAttribute("target", "_parent");

                    iframe.contentWindow.document.head.append(base);
                  });
                } else {
                  alertBox({ message: response.data.message, type: "error" });
                  $(this).prop("disabled", false);
                }
              }
            );
          },
          cancelFunc: function () {
            $(that).prop("disabled", false);
            return false;
          },
        });
      });

      $("#depositBtn").click(function (e) {
        e.preventDefault();
        var that = this;
        api.checkLogin("", function (response) {
          if (response.data) {
            location.href = $(that).attr("href");
          } else {
            alertBox({
              message: translate("您还没有登录！"),
              type: "warning",
              buttonText: {
                confirmText: translate("游客登录"),
              },
              cancelFunc: function cancelFunc() {
                return false;
              },
              confirmFunc: function confirmFunc() {
                $("#guestLoginBtn").trigger("click");
              },
            });
          }
        });
      });
    }

    //! register
    {
      let isUsernameExist = "";
      const form = $("#registerForm");

      if (window.affCode) {
        form.find('input[name="affKey"]').val(window.affCode);
      }

      form.find('[name="username"]').on("change", function (event) {
        event.preventDefault();
        const name = $(this).val();
        api.checkValidUser(
          {
            username: name,
          },
          function (data) {
            form.find('[data-hint-for="username"]').text("*" + (data || translate("该账号可用")));
            isUsernameExist = data || "";
          }
        );
      });

      form.find('button[type="submit"]').on("click", (event) => {
        event.preventDefault();
        const button = $(this);
        button.prop("disabled", true);
        function enable() {
          button.prop("disabled", false);
        }

        const password = form.find('input[name="password"]').val();
        const password1 = form.find('input[name="password1"]').val();
        const username = form.find('input[name="username"]').val();
        const affKey = form.find('input[name="affKey"]').val();
        const weChatId = form.find('input[name="wechat"]').val();
        const qqId = form.find('input[name="qq"]').val();
        const mobile = form.find('input[name="mobile"]').val();
        const otp = form.find('input[name="mobileOtp"]').val();
        const email = form.find('input[name="email"]').val();
        const name = form.find('input[name="fullName"]').val();
        const emailRegEx = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
        const mobileRegEx = new RegExp("^1(3|4|5|6|7|8|9)\\d{9}$");
        const weChatRegEx = new RegExp("^[a-zA-Z0-9_]+$");
        const qqRegEx = new RegExp("^[0-9]*$");

        if (isUsernameExist !== "") return alertBox({ message: isUsernameExist, type: "info", confirmFunc: enable });
        if (username == "") return alertBox({ message: translate("请输入账号"), type: "error", confirmFunc: enable });
        if (username.length < 4 || username.length > 15)
          return alertBox({ message: translate("账户名由4-15个字符组成"), type: "info", confirmFunc: enable });

        /**
         * Aff checking will be checking in XIANJIN-2951
         */
        // if (affKey == "") return alertBox({ message: "请输入邀请码", type: "info", confirmFunc: enable });

        if (name == "") return alertBox({ message: translate("请输入账号名称！"), type: "info", confirmFunc: enable });

        if (email != undefined && email == "") {
          return alertBox({ message: translate("请输入正确的邮箱地址格式"), type: "info", confirmFunc: enable });
        } else {
          if (email != undefined && !emailRegEx.test(email)) {
            return alertBox({ message: translate("请输入正确的邮箱地址格式"), type: "info", confirmFunc: enable });
          }
        }

        // if (mobile != undefined && (mobile == "" || !mobileRegEx.test(mobile) || mobile.length > 11))
        //   return alertBox({message: "请输入正确的11位数字手机号码", type: "info", confirmFunc: enable});

        if (weChatId !== undefined && weChatId.trim() == "") {
          return alertBox({ message: translate("请输入微信号！"), type: "info", confirmFunc: enable });
        } else if (weChatId !== undefined && (!weChatRegEx.test(weChatId) || weChatId.length < 4)) {
          return alertBox({ message: translate("请输入正确的微信号！"), type: "info", confirmFunc: enable });
        }

        if (qqId !== undefined && qqId.trim() == "") {
          return alertBox({ message: translate("请输入QQ号！"), type: "info", confirmFunc: enable });
        } else if (qqId !== undefined && (!qqRegEx.test(qqId) || qqId.length < 6)) {
          return alertBox({ message: translate("请输入正确的QQ号！"), type: "info", confirmFunc: enable });
        }

        if (password == "") return alertBox({ message: translate("请输入密码"), type: "info", confirmFunc: enable });

        if (password.length < 6 || password.length > 20)
          return alertBox({
            message: translate("密码由6-20个字符包含英文字母和数字"),
            type: "info",
            confirmFunc: enable,
          });

        if (password != password1)
          return alertBox({ message: translate("输入的密码不一致，请重新输入"), type: "warning", confirmFunc: enable });

        let affId = this.getQueryVariable("aff");
        if (affId == "" || affId == null) {
          affId = this.readCookie("affid");
        }

        let id = this.getQueryVariable("id");

        /**
         * This will use slideCaptcha instead of input code
         */
        SwipeCaptcha({
          selector: document.body,
          successCallback: ({ cryptograph, code }) => {
            if (code == "") return alertBox({ message: translate("请重新验证"), type: "info", confirmFunc: enable });

            const data = {
              parent: affId,
              id,
              username,
              password,
              affKey,
              weChatId,
              qqId,
              mobile,
              code,
              cryptograph,
              email,
              name,
              mobileOtpCode: otp && otp.toString(),
            };

            api.register(data, (response) => {
              if (response.data.success) {
                if (response.data.data && response.data.data.isOtpRequired) {
                  const { mobile, id, username } = response.data.data.user;
                  this.checkOTP({ mobile, username, userpassid: id, isReg: true });
                } else {
                  alertBox({
                    message: translate("恭喜，注册成功！"),
                    type: "success",
                    confirmFunc: () => {
                      api.login(data, (response) => {
                        if (response.data.success) {
                          this.popupAgreementPage(response.data.message);
                        }
                      });
                    },
                  });
                }

                if (form.parents(".modal").length !== 0) {
                  form.parents(".modal").find("[data-micromodal-close]").off().click();
                }
                // don't clear inputs when submit
                // form.find("input").val("");
              } else {
                if (response.data.message && response.data.message.length > 0) {
                  switch (response.data.message) {
                    case "验证码有误":
                    case translate("验证码有误"):
                      alertBox({
                        message: response.data.message,
                        type: "error",
                        confirmFunc: () => {
                          enable();
                        },
                      });
                      break;
                    default:
                      alertBox({
                        message: response.data.message,
                        type: "error",
                        confirmFunc: () => {
                          enable();
                        },
                      });
                  }
                }

                if (response.data.data && response.data.data.length > 0) {
                  alertBox({
                    messageList: response.data.data,
                    type: "error",
                    confirmFunc: () => {
                      enable();
                    },
                  });
                }
              }
            });
          },
          closeCallback: () => {
            return;
          },
        });
      });
    }

    //! register with group error message
    {
      let tempUsername = "";
      let errorValues = {};

      const errorIcon = `
          <svg xmlns="http://www.w3.org/2000/svg" width="13.165" height="13.165" viewBox="0 0 13.165 13.165">
            <path id="Icon_material-error-outline" data-name="Icon material-error-outline" d="M8.924,11.557h1.316v1.316H8.924Zm0-5.266h1.316v3.949H8.924ZM9.576,3a6.582,6.582,0,1,0,6.589,6.582A6.579,6.579,0,0,0,9.576,3Zm.007,11.848a5.266,5.266,0,1,1,5.266-5.266A5.264,5.264,0,0,1,9.582,14.848Z" transform="translate(-3 -3)" fill="#fff"/>
          </svg>
        `;
      const errorBox =
        `
          <div class="error-message-box" style="display: none;">
            <div class="error-message-title">${errorIcon}<span>` +
        translate("错误") +
        `</span></div>
            <div class="error-message-content" api-combined-error="api-combined-error"></div>
          </div>
          `;
      const form = $("#registerFormWithGroupError");

      if (!$(".error-message-box").length) form.append(errorBox).after(form);

      const updateErrorMessageList = (_errorValues) => {
        const _errorList = Object.values(_errorValues).filter((error) => !!error);
        if (_errorList.length !== 0) {
          $("[api-combined-error]").html(_errorList);
          $("#registerFormWithGroupError .error-message-box").show();
        } else {
          $("#registerFormWithGroupError .error-message-box").hide();
        }
      };

      // set the error into array
      if (window.affCode) {
        form.find('input[name="affKey"]').val(window.affCode);
      }

      form.find('[name="username"]').on("blur", function (event) {
        event.preventDefault();
        const name = $(this).val();

        if (name !== "" && tempUsername !== name) {
          api.checkValidUser(
            {
              username: name,
            },
            function (data) {
              if (data) {
                errorValues = { ...errorValues, username: `<span>${data}</span>` };
                form.find('[data-hint-for="username"]').empty();
              } else {
                errorValues = { ...errorValues, username: "" };
                form.find('[data-hint-for="username"]').text(`*${translate("该账号可用")}`);
              }

              tempUsername = name;

              updateErrorMessageList(errorValues);
            }
          );
        }
      });

      form.find('button[type="submit"]').on("click", (event) => {
        event.preventDefault();
        const button = $(this);
        button.prop("disabled", true);
        function enable() {
          button.prop("disabled", false);
        }

        const password = form.find('input[name="password"]').val();
        const password1 = form.find('input[name="password1"]').val();
        const username = form.find('input[name="username"]').val();
        const affKey = form.find('input[name="affKey"]').val();
        const weChatId = form.find('input[name="wechat"]').val();
        const qqId = form.find('input[name="qq"]').val();
        const mobile = form.find('input[name="mobile"]').val();
        const email = form.find('input[name="email"]').val();
        const name = form.find('input[name="fullName"]').val();
        const otp = form.find('input[name="mobileOtp"]').val();
        const emailRegEx = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-za-z0-9]+$/;
        const mobileRegEx = new RegExp("^1[3456789]\\d{9}$"); // "^[0-9]*$"
        const weChatRegEx = new RegExp("^[a-zA-Z0-9-_]+$");
        errorValues = {};

        if (username.length < 4 || username.length > 15) {
          errorValues = { ...errorValues, username: `<span>${translate("账号由4-15个字符组成")}</span>` };
        } else {
          api.checkValidUser(
            {
              username,
            },
            function (data) {
              if (data) {
                errorValues = { ...errorValues, username: `<span>${data}</span>` };
                form.find('[data-hint-for="username"]').empty();
              } else {
                errorValues = { ...errorValues, username: "" };
                form.find('[data-hint-for="username"]').text(`*${translate("该账号可用")}`);
              }

              updateErrorMessageList(errorValues);
            }
          );
        }

        /**
         * Aff checking will be checking in XIANJIN-2951
         */
        // if (affKey == "") return console.log({ message: "请输入邀请码", type: "info" });

        if (name == "") {
          errorValues = { ...errorValues, fullName: `<span>${translate("请输入正确的姓名格式")}</span>` };
        }

        if (email == "") {
          errorValues = { ...errorValues, email: `<span>${translate("请输入正确的邮箱地址格式")}</span>` };
        } else {
          if (email != undefined && !emailRegEx.test(email)) {
            errorValues = { ...errorValues, email: `<span>${translate("请输入正确的邮箱地址格式")}</span>` };
          }
        }

        if (mobile != undefined && (mobile == "" || !mobileRegEx.test(mobile))) {
          errorValues = { ...errorValues, mobile: `<span>${translate("请输入有效的手机号码")}</span>` };
        }

        if (otp != undefined && otp === "") {
          errorValues = { ...errorValues, mobileOtp: `<span>${translate("请输入手机验证码")}</span>` };
        }

        if (weChatId !== undefined && weChatId.trim() == "") {
          errorValues = { ...errorValues, wechat: `<span>${translate("请输入微信号")}</span>` };
        } else if (weChatId !== undefined && (!weChatRegEx.test(weChatId) || weChatId.length < 4)) {
          errorValues = { ...errorValues, wechat: `<span>${translate("微信号错误")}</span>` };
        }

        if ((qqId !== undefined && qqId.trim() == "") || (qqId !== undefined && qqId.trim().length < 4)) {
          errorValues = { ...errorValues, qq: `<span>${translate("请输入正确的QQ号资料")}</span>` };
        }

        if (password == "") {
          errorValues = { ...errorValues, password: `<span>${translate("请输入密码")}</span>` };
        } else {
          if (password.length < 6 || password.length > 20) {
            errorValues = {
              ...errorValues,
              password: `<span>${translate("密码由6-20个字符包含英文字母和数字")}</span>`,
            };
          }

          if (password != password1) {
            errorValues = { ...errorValues, password1: `<span>${translate("输入的密码不一致，请重新输入")}</span>` };
          }
        }

        updateErrorMessageList(errorValues);

        if (Object.values(errorValues).filter((error) => !!error).length !== 0) {
          return;
        }

        let affId = this.getQueryVariable("aff");
        if (affId == "" || affId == null) {
          affId = this.readCookie("affid");
        }

        let id = this.getQueryVariable("id");

        /**
         * This will use slideCaptcha instead of input code
         */
        SwipeCaptcha({
          selector: document.body,
          successCallback: ({ cryptograph, code }) => {
            if (code == "") {
              errorValues = { ...errorValues, mobileOtp: `<span>${translate("请重新验证")}</span>` };
              return updateErrorMessageList(errorValues);
            }

            const data = {
              parent: affId,
              id,
              username,
              password,
              affKey,
              weChatId,
              qqId,
              mobile,
              code,
              cryptograph,
              email,
              name,
              mobileOtpCode: otp && otp.toString(),
            };

            api.register(data, (response) => {
              if (response.data.success) {
                if (response.data.data && response.data.data.isOtpRequired) {
                  const { mobile, id, username } = response.data.data.user;
                  this.checkOTP({ mobile, username, userpassid: id, isReg: true });
                } else {
                  alertBox({
                    message: translate("注册成功"),
                    type: "success",
                    confirmFunc: () => {
                      api.login(data, (response) => {
                        if (response.data.success) {
                          this.popupAgreementPage(response.data.message);
                        }
                      });
                    },
                  });
                }

                if (form.parents(".modal").length !== 0) {
                  form.parents(".modal").find("[data-micromodal-close]").off().click();
                }
                // don't clear inputs when submit
                // form.find("input").val("");
              } else {
                if (response.data.message && response.data.message.length > 0)
                  errorValues = { errorMessage: `<span>${response.data.message}</span>` };
                if (response.data.data && response.data.data.length > 0) {
                  for (let i = 0; i < response.data.data.length; i++) {
                    errorValues = { i: `<span>${response.data.data[i]}</span>` };
                  }
                }

                return updateErrorMessageList(errorValues);
              }
            });
          },
          closeCallback: () => {
            return;
          },
        });
      });
    }

    //! forgot password
    {
      const form = $("#forgotPasswordForm");
      form.find('button[type="submit"]').click(async (event) => {
        event.preventDefault();
        const button = this;
        $(button).prop("disabled", true);
        function enable() {
          $(button).prop("disabled", false);
        }
        const { cryptograph, code } = await this.getPuzzleCaptcha(enable);

        const data = {
          username: form.find('[name="username"]').val(),
          mobile: form.find('[name="mobile"]').val(),
          cryptograph,
          code,
        };

        if (data.username == "")
          return alertBox({ message: translate("请输入帐号"), type: "info", confirmFunc: enable });
        if (data.mobile == "")
          return alertBox({ message: translate("请输入绑定的手机号"), type: "info", confirmFunc: enable });

        api.checkNameAndCode(data, (response) => {
          if (response.data.success) {
            location.href = "editpwd.html?name=" + data.username;
          } else {
            switch (response.data.message) {
              case translate("验证码有误"):
                alertBox({
                  type: "error",
                  message: response.data.message,
                  confirmFunc: () => {
                    enable();
                  },
                });
                break;
              default:
                alertBox({
                  type: "error",
                  message: response.data.message,
                  confirmFunc: () => {
                    enable();
                  },
                });
            }
          }
        });
      });
    }
    //! set password
    {
      const form = $("#setPasswordForm");
      form.find('button[type="submit"]').click((event) => {
        event.preventDefault();
        const button = this;
        $(button).prop("disabled", true);
        function enable() {
          $(button).prop("disabled", false);
        }
        const password = form.find('[name="password"]').val();
        const password1 = form.find('[name="password1"]').val();
        const code = form.find('[name="code"]').val();

        if (password == "") return alertBox({ message: translate("请输入新密码"), type: "info", confirmFunc: enable });
        if (code == "") return alertBox({ message: translate("请输入验证码"), type: "info", confirmFunc: enable });
        if (password != password1)
          return alertBox({ message: translate("确认密码不相符"), type: "info", confirmFunc: enable });

        const data = {
          password,
          code,
          name: this.getQueryVariable("name"),
        };

        api.setPassword(data, function (response) {
          if (response.data.success) {
            alertBox({
              type: "success",
              message: translate("密码修改成功"),
              confirmFunc: function () {
                location.href = "/";
              },
            });
          } else {
            alertBox({ type: "error", message: response.data.message, confirmFunc: enable });
          }
        });
      });
    }

    //Append PC app download function
    {
      $("body").append($(`<iframe class="download-frame" style="display: none;" src=""></iframe>`));
      if (this.isPCAppElectron()) {
        $(".pcapp-download").remove();
      }

      $(".pcapp-download").click(function (e) {
        e.preventDefault();
        const downloadLink = this.href;
        $(".download-frame").attr("src", downloadLink);
      });

      window.addEventListener("message", ({ data: eventData }) => {
        if (eventData === "DOWNLOAD_DONE") {
          setTimeout(function () {
            $(".download-frame").attr("src", "");
          }, 1000);
        }
      });
    }
  };

  /**
   * GENERATE VERIFY CODE
   */
  updateVCode = () => {
    const { $, api } = this;
    if ($("img.verify-code").length) {
      $("img.verify-code").css("pointer-events", "none");
      api.generateVCode(function (vcode) {
        $("img.verify-code").attr("src", vcode);
        $("img.verify-code").removeAttr("style");
      });
    }
  };

  /**
   * RENDER QR CODE
   */
  renderQrCode = (selector) => {
    const { $, QRCode, isElement } = this;
    selector.each(function (i, elem) {
      const target = $(elem);
      if (target.has("canvas").length !== 0) {
        target.find("canvas").remove();
      }
      target.append($("<canvas></canvas>"));
      const qrTarget = target.find("canvas");
      let url = target.data("qr-url");
      const size = target.data("size") || 150;
      const middleWareUrl = target.data("qr-middleware");
      const darkColor = target.data("qr-dark-color") || "#000000";
      const options = {
        margin: 1,
        width: size,
        height: size,
        color: {
          dark: darkColor,
        },
      };
      let shortUrl = url ? url.toString().split("://").slice(-1) : url;
      let currTarget = qrTarget;
      if (!isElement(qrTarget)) {
        currTarget = qrTarget[0];
      }
      // url = url;
      target.attr("title", url);
      if (target.parents(".appqr-wrapper").length !== 0) {
        target.parents(".appqr-wrapper").find(".appdlurl").text(shortUrl);
      }
      if (middleWareUrl) {
        // Call middleware server to request available domain
        var newUrl = "";
        if (middleWareUrl.indexOf("http://") === 0) {
          newUrl = middleWareUrl.replace("http://", "https://");
        } else {
          newUrl = middleWareUrl;
        }

        window.Pace.ignore(function () {
          $.ajax({
            url: newUrl + "/active",
            error: function (e) {
              console.error(e);
              QRCode.toCanvas(currTarget, url, options, function (error) {
                if (error) console.error(error);
              });
            },
          }).done(function (availableMiddleware) {
            url = "https://" + availableMiddleware + "?r=" + btoa(url) + "1";
            QRCode.toCanvas(currTarget, url, options, function (error) {
              if (error) console.error(error);
            });
          });
        });
      } else {
        QRCode.toCanvas(currTarget, url, options, function (error) {
          if (error) console.error(error);
        });
      }
    });
  };

  /**
   * APPEND GA SCRIPT
   */
  appendGAScript = (data) => {
    const gaId = data["host.ga.trackid"];
    if (!gaId) return;
    (function (i, s, o, g, r, a, m) {
      i["GoogleAnalyticsObject"] = r;
      (i[r] =
        i[r] ||
        function () {
          (i[r].q = i[r].q || []).push(arguments);
        }),
        (i[r].l = 1 * new Date());
      (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
      a.async = 1;
      a.src = g;
      m.parentNode.insertBefore(a, m);
    })(window, document, "script", "https://www.google-analytics.com/analytics.js", "ga");

    ga("create", gaId, "auto");
    ga("send", "pageview");
  };

  /**
   * DETECT ELEMENT
   */
  isElement = (obj) => {
    try {
      //Using W3 DOM2 (works for FF, Opera and Chrome)
      return obj instanceof HTMLElement;
    } catch (e) {
      //Browsers not supporting W3 DOM2 don't have HTMLElement and
      //an exception is thrown and we end up here. Testing some
      //properties that all elements have (works on IE7)
      return (
        typeof obj === "object" &&
        obj.nodeType === 1 &&
        typeof obj.style === "object" &&
        typeof obj.ownerDocument === "object"
      );
    }
  };

  /**
   * GET QUERY VARIABLE
   */
  getQueryVariable = (variable) => {
    const query = window.location.search.substring(1);
    const variables = query.split("&");
    for (let i = 0; i < variables.length; i++) {
      const pair = variables[i].split("=");
      if (decodeURIComponent(pair[0]) == variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    console.log("Query variable %s not found", variable);
  };

  /**
   * READ COOKIE
   */
  readCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(";");

    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  };

  /**
   * ERASE COOKIE
   */
  eraseCookie = (name) => {
    this.createCookie(name, "", -1);
  };

  /**
   * CHECK ONE TIME PASSWORD
   */
  checkOTP = ({ mobile, username, userpassid, isReg }) => {
    const { api } = this;
    const { alertBox } = window;
    const { $, maskMobileNumber } = this;
    const isNewMemberPage = !!$("body[api-member-page='new']").length;

    const wrapper = $(`  <div class="alert-box-wrapper otp-wrapper" aria-hidden="false"> </div>`);
    const container = $(`<div 
      class="alert-box info otp-container"
      aria-hidden="false"
      style="display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-top: 4rem;">
    </div>`);
    const icon = $(`
      <div class="alert-box-icon alert-box-info alert-box-animate-info-icon" style="display: flex;">
        <span class="alert-box-icon-text">i</span>
      </div>
    `);
    const resendLink = $(`<a class="otp-container__button" href="#">${translate("重发验证码")}</a>`);

    resendLink.turnOff = function () {
      this.attr("disabled", true).css("color", "#34c1ee").css("pointerEvents", "none").css("color", "gray");
    };
    resendLink.turnOn = function () {
      this.removeAttr("disabled").css("pointerEvents", "").css("color", "#34c1ee");
    };

    resendLink.turnOff();

    function newButton(text) {
      const elem = $(`<button class="otp-container__button"></button>`);
      elem.text(text);
      elem.addClass("btn-confirm");
      elem.turnOff = function () {
        this.prop("disabled", true).css("opacity", 0.5).css("pointerEvents", "none");
      };
      elem.turnOn = function () {
        this.removeAttr("disabled").css("opacity", 1).css("pointerEvents", "initial");
      };
      return elem;
    }

    const display = $(
      `<p class="otp-container__title">${translate("手机验证码已发送至")}${maskMobileNumber(mobile)}</p>`
    );
    display.changeMobile = function (mobile) {
      this.text(`${translate("手机验证码已发送至")}${maskMobileNumber(mobile)}`);
    };

    const counter = $(`<p>&nbsp</p>`);
    const inputFacode = $(
      `<input style="height: 30px; border: 1px solid #ddd; border-radius: 12px; text-align: center; color: #333;" type="tel" name="facode" id="facode" maxlength="12" placeholder="${translate(
        "输入验证码"
      )}"/>`
    );
    const action = $(`<div class="action"></div>`);
    action.css("textAlign", "center");
    const confirmBtn = newButton(translate("确定验证"));
    confirmBtn.turnOff();
    const changeMobileBtn = newButton(translate("修改手机号")); // temp removed from containerOTP.append to ease JAVA's workflow
    changeMobileBtn.turnOff();

    const containerChangeMobile = $(`<div></div>`);
    containerChangeMobile.css("textAlign", "center");
    containerChangeMobile.css("padding", "1rem");
    containerChangeMobile.css("borderRadius", "0.2rem");
    const inputNewMobile = $(
      `<input style="height: 30px; border: 1px solid #ddd; border-radius: 6px; text-align: center; color: #333;" type="tel" name="newMobile" id="newMobile" placeholder="${translate(
        "输入手机号码"
      )}"/>`
    );
    inputNewMobile.on("input", function () {
      if ($(this).val()) {
        mobile = $(this).val();
        confirmMobileBtn.turnOn();
      } else {
        confirmMobileBtn.turnOff();
      }
    });

    const action2 = $(`<div class="action"></div>`);
    action2.css("textAlign", "center").css("width", "100%");
    const confirmMobileBtn = newButton(translate("发送验证码"));
    // Turn offf resend link and change mobile
    confirmMobileBtn.click(() => {
      const newMobile = inputNewMobile.val();
      api
        .resendOTP({ mobile: newMobile, username, userpassid })
        .then((res) => {
          display.changeMobile(newMobile);
          containerOTP.slideDown();
          containerChangeMobile.slideUp();
          changeMobileBtn.turnOff();
          resendLink.turnOff();
          inputFacode.css("pointerEvents", "initial");
          duration = 30;
          countdown();
        })
        .catch(function (err) {
          alertBox({ message: err.response.data.message, type: "error" });
        });
    });
    action2.append(confirmMobileBtn);

    const mobileTitle = `<h1 style="font-size: 20px; margin-bottom: 14px; color: #333;">${translate(
      "绑定手机号码"
    )}</h1>`;
    const mobileDescription = `<p style="font-size: 14px; color: #666;">${translate("请输入手机号码")}</p>`;

    containerChangeMobile.append(mobileTitle, mobileDescription, inputNewMobile, action2);

    const otpTitle = `<h1 class="otp-container__title" style="margin-bottom: 10px; color: #333;">${translate(
      "手机验证码"
    )}</h1>`;
    const otpDescription = `<p class="otp-container__description">${
      isReg ? translate("注册成功！请验证手机号码。") : translate("请验证手机号码")
    }</p>`;

    const containerOTP = $('<div class="otp-container__input-wrapper"></div>');
    containerOTP.css("textAlign", "center");
    containerOTP.append(otpTitle, otpDescription, inputFacode, display, resendLink, counter, action.append(confirmBtn));

    function changeMobile() {
      if (mobile) inputNewMobile.val(mobile);
      confirmBtn.turnOff();
      resendLink.turnOff();
      confirmMobileBtn.turnOff();
      inputFacode.css("pointerEvents", "none");
      containerChangeMobile.slideDown();
      changeMobileBtn.turnOff();
    }

    let duration = 30;

    changeMobileBtn.click(changeMobile);
    if (!mobile) {
      containerOTP.hide();
      changeMobile();
    } else {
      containerChangeMobile.hide();
      countdown();
    }

    function countdown() {
      counter.text(`${duration} ${translate("秒")}`);
      duration--;
      if (duration > 0) {
        window.setTimeout(countdown, 1000);
      } else {
        counter.html("&nbsp");
        resendLink.turnOn();
        changeMobileBtn.turnOn();
      }
    }

    resendLink.click(() => {
      resendLink.turnOff();
      changeMobileBtn.turnOff();
      api
        .resendOTP({ mobile, username, userpassid })
        .then((res) => {})
        .catch(function (err) {
          alertBox({ message: err.response.data.message, type: "error" });
        });
      duration = 30;
      countdown();
    });

    confirmBtn.click(() => {
      const code = $('input[name="facode"]').val();
      api
        .validateOTP({ userpassid, facode: code })
        .then((res) => {
          alertBox({
            message: translate("验证成功！"),
            type: "success",
            confirmFunc: () => {
              wrapper.remove();
              this.popupAgreementPage();
            },
          });
        })
        .catch((err) => {
          alertBox({ message: err.response.data.message, type: "error" });
        });
      // wrapper.remove();
    });

    container.append(icon, containerOTP, containerChangeMobile);
    wrapper.append(container);
    $("body").append(wrapper);

    inputFacode.on("input", function () {
      if ($(this).val()) {
        confirmBtn.turnOn();
      } else {
        confirmBtn.turnOff();
      }
    });

    inputFacode.on("keydown", blockNotNumber);
    inputNewMobile.on("keydown", blockNotNumber);

    function blockNotNumber(event) {
      if (event.key.length == 1 && /\D/.test(event.key)) {
        event.preventDefault();
      }
    }
  };

  /** To toggle password reveal **/
  passwordReveal = () => {
    let hasSetCustomStyleSheet = false;
    const { $ } = this;
    const passwordSelectors = $(".password-reveal-wrapper");
    if (passwordSelectors.length === 0) return;

    passwordSelectors.each(function (e) {
      let showPassword = false;
      let inputActionsWidth = 0;
      const passwordSelector = $(this);
      const passwordField = passwordSelector.find('input[name^="password"]');
      const inputHeight = passwordField.height();
      const inputPaddingRight = passwordSelector.data("padding-right");
      const offsetRight = passwordSelector.data("right") || 4;
      const customizedColor = passwordSelector.data("color");
      const theme = passwordSelector.data("theme") || "dark";
      const iconColor = customizedColor ? customizedColor : theme === "dark" ? "#333" : "#fff";

      const toggleButton = $("<button class='password-reveal-wrapper__toggle-password-reveal-btn'></button>");

      const eye = $(
        '<svg class="password-reveal-wrapper__toggle-password-reveal-btn__eye" style="color: ' +
          iconColor +
          '" id="Component_1535_26" data-name="Component 1535 – 26" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">\n' +
          '  <g id="Rectangle_42528" data-name="Rectangle 42528" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">\n' +
          '    <rect width="16" height="16" stroke="none"/>\n' +
          '    <rect x="0.5" y="0.5" width="15" height="15" fill="none"/>\n' +
          "  </g>\n" +
          '  <g id="Group_98498" data-name="Group 98498" transform="translate(2.002 4.292)">\n' +
          '    <g id="eye-close-up">\n' +
          '      <path id="Path_254" data-name="Path 254" d="M6,161.525a6.585,6.585,0,0,0-5.974,3.81.257.257,0,0,0,0,.217,6.588,6.588,0,0,0,11.947,0,.257.257,0,0,0,0-.217A6.585,6.585,0,0,0,6,161.525Zm0,6.624a2.7,2.7,0,1,1,2.7-2.7A2.7,2.7,0,0,1,6,168.149Z" transform="translate(0 -161.525)" fill="currentColor"/>\n' +
          '      <ellipse id="Ellipse_12" data-name="Ellipse 12" cx="1.731" cy="1.731" rx="1.731" ry="1.731" transform="translate(4.267 2.188)" fill="currentColor"/>\n' +
          "    </g>\n" +
          "  </g>\n" +
          "</svg>\n"
      );

      const eyeSlashed = $(
        '<svg class="password-reveal-wrapper__toggle-password-reveal-btn__eye-slashed" style="color: ' +
          iconColor +
          '" id="Component_1535_26" data-name="Component 1535 – 26" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">\n' +
          '  <g id="Group_101349" data-name="Group 101349" transform="translate(-293.499 -560.535)">\n' +
          '    <g id="eye-close-up" transform="translate(295.499 564.827)">\n' +
          '      <path id="Path_254" data-name="Path 254" d="M6,161.525a6.585,6.585,0,0,0-5.974,3.81.257.257,0,0,0,0,.217,6.588,6.588,0,0,0,11.947,0,.257.257,0,0,0,0-.217A6.585,6.585,0,0,0,6,161.525Zm0,6.624a2.7,2.7,0,1,1,2.7-2.7A2.7,2.7,0,0,1,6,168.149Z" transform="translate(0 -161.525)" fill="currentColor"/>\n' +
          '      <ellipse id="Ellipse_12" data-name="Ellipse 12" cx="1.731" cy="1.731" rx="1.731" ry="1.731" transform="translate(4.267 2.188)" fill="currentColor"/>\n' +
          "    </g>\n" +
          '    <line id="Line_2814" data-name="Line 2814" x1="6.63" y2="8.491" transform="translate(298.181 564.5)" fill="none" stroke="currentColor" stroke-linecap="round" stroke-width="1"/>\n' +
          "  </g>\n" +
          '  <g id="Rectangle_42528" data-name="Rectangle 42528" fill="#fff" stroke="#707070" stroke-width="1" opacity="0">\n' +
          '    <rect width="16" height="16" stroke="none"/>\n' +
          '    <rect x="0.5" y="0.5" width="15" height="15" fill="none"/>\n' +
          "  </g>\n" +
          "</svg>\n"
      );

      toggleButton.css("right", offsetRight + "px").append(eye);
      toggleButton.click(function (e) {
        e.preventDefault();
        $(this).empty();
        showPassword = !showPassword;

        if (showPassword) {
          $(this).append(eyeSlashed);
          $(this).parent(".password-reveal-wrapper").find('input[name^="password"]').prop("type", "text");
        } else {
          $(this).append(eye);
          $(this).parent(".password-reveal-wrapper").find('input[name^="password"]').prop("type", "password");
        }
      });

      passwordSelector.css({
        position: "relative",
      });
      passwordSelector.append(toggleButton);

      /**
       * To re-adjust the padding of the input value
       */
      const inputActions = passwordSelector.find('> *:not(input[name^="password"])');

      inputActions.each(function () {
        inputActionsWidth += $(this).outerWidth();
      });

      passwordField.css("padding-right", inputPaddingRight ? inputPaddingRight : inputActionsWidth);

      if (!hasSetCustomStyleSheet) {
        $("head").append(`<style>
          .password-reveal-wrapper .password-reveal-wrapper__toggle-password-reveal-btn {
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            top: 50%;
            transform: translateY(-50%);
            position: absolute;
            background: transparent;
            padding: 0;
            outline: none;
            border: 0;
            height: ${inputHeight}px;
            width: 20px;
          }
        </style>`);

        hasSetCustomStyleSheet = true;
      }
    });
  };

  /** To enable OTP request form in register **/
  registerSendOtp = () => {
    let hasSetCustomStyleSheet = false;
    const { $, api, maskMobileNumber } = this;
    const { alertBox } = window;

    const otpContainerSelector = $(".register-otp-wrapper");
    const otpButton = $("#mobileOtpCode");
    const otpHint = $(".mobileOtp .hint");
    const otpButtonSelector = otpContainerSelector.find("#mobileOtpCode");
    const offsetRight = otpButtonSelector.data("right") || 4;

    otpButtonSelector.css("right", offsetRight + "px");

    // OTP register
    {
      let duration = 60;

      if (otpButton.length === 0) return;

      const toggleBtnDisable = (val) => otpButton.css("pointer-events", val);

      const countdown = () => {
        let origin = otpButton.text();
        var resendCountdown = setInterval(() => {
          otpButton.html(`${origin}(${duration})`);
          duration--;

          if (duration < 0) {
            clearInterval(resendCountdown);
            otpButton.html(translate("重发验证码"));
            toggleBtnDisable("auto");
          }
        }, 1000);
      };

      otpButton.click(function (e) {
        e.preventDefault();
        const form = $(this).parents("form");
        const username = form.find('input[name="username"]').val();
        const mobile = form.find('input[name="mobile"]').val();

        if (mobile === "" || !mobile) {
          alertBox({ message: translate("请输入手机号码"), type: "error" });
          return;
        }

        if (duration < 0) duration = 60; // reset timer

        api
          .resendOTP({ mobile, username })
          .then((res) => {
            if (res) {
              if (otpHint.length !== 0) {
                otpHint.text(`${translate("手机验证码已发送至")}${maskMobileNumber(mobile)}`).css("color", "#2161b3");
              }
              alertBox({ message: translate("发送成功"), type: "success" });
              toggleBtnDisable("none");
              countdown();
            }
          })
          .catch(function (err) {
            alertBox({ message: err.response.data.message, type: "error" });
          });
      });
    }

    if (!hasSetCustomStyleSheet) {
      $("head").append(`<style>
        .register-otp-wrapper {
          position: relative;
        }

        .register-otp-wrapper #mobileOtpCode {
          cursor: pointer;
          position: absolute;
          background-color: #ccc;
          padding: 6px 10px;
          top: 50%;
          transform: translateY(-50%);
          border: 0;
          outline: none;
          border-radius: 4px;
        }
      </style>`);

      hasSetCustomStyleSheet = true;
    }
  };
}
