import { getApUid } from 'src/api/categories/config';
import AlertBox from 'src/lib/alertBox';

interface ILoginParam {
  username: string;
  password: string;
  code?: number;
  cryptograph?: string;
}

interface ICheckValidUser {
  username: string;
}

interface ICheckNameAndCode {
  username: string;
  mobile: string;
  code: string;
  cryptograph: string;
}

interface ISendGetPasswordCode {
  username: string;
  mobile: string;
}

interface ISetPassword {
  name: string;
  code: number;
  password: string;
}
interface ISetPassword {
  name: string;
  code: number;
  password: string;
}

interface IRegisterData {
  parent?: string;
  username: string;
  password: string;
  affKey?: number;
  weChatId?: string;
  qqId?: string;
  mobile?: string;
  code: number;
  cryptograph?: string;
  email?: string;
  name?: string;
}

interface IResendOtp {
  mobile: number;
  userpassid: string;
  username: string;
}

class ApiAccount {
  ajax: any;
  constructor(ajax) {
    this.ajax = ajax;
  }
  login = ({ username, password, code, cryptograph }: ILoginParam, callback: (response) => void) => {
    const { ajax } = this;
    ajax
      .request({
        url: "/web/rest/cashlogin",
        method: "post",
        headers: { apuid: getApUid() },
        params: {
          account: username,
          password: password,
          code: code,
          cryptograph: cryptograph,
        },
      })
      .then(function (response) {
        // if(response.data.)
        if (callback) {
          callback(response);
        }
      })
      .catch(function (error) {
        // handle error
      })
      .then(function () {
        // always executed
      });
  };

  getUserInfo = (callback: (response) => void) => {
    const { ajax } = this;
    ajax.get("/web/rest/member/userInfo", { headers: { apuid: getApUid() } }).then(function (response) {
      if (response) {
        if (callback) {
          callback(response);
        }
      }
    });
  };

  checkLogin = (url: string, callback: (response) => void) => {
    const { ajax } = this;
    if (!url) {
      url = "/member/checklogin?client=?" + Math.random();
    } else {
      url = "/member/checklogin" + url;
    }
    ajax
      .request({ url: url, method: "post", headers: { apuid: getApUid() } })
      .then(function (response) {
        if (callback) {
          callback(response);
        }
        if (response.data.success) {
          sessionStorage.SessionName = "SessionData";
        }
      })
      .catch(function (error) {
        // handle error
        if (callback) {
          callback("");
        }
      });
  };

  checkValidUser = ({ username }: ICheckValidUser, callback: (response) => void) => {
    const { ajax } = this;
    ajax
      .request({
        url: "/web/rest/check",
        method: "get",
        headers: { apuid: getApUid() },
        params: {
          username: username,
        },
      })
      .then(function (response) {
        if (callback) {
          callback(response.data);
        } else {
          console.log(response);
        }
      });
  };

  checkNameAndCode = ({ username, mobile, code, cryptograph }: ICheckNameAndCode, callback: (response) => void) => {
    const { ajax } = this;
    ajax
      .request({
        url: "/web/rest/checkNameAndCode",
        method: "get",
        headers: { apuid: getApUid() },
        params: {
          username,
          mobile,
          code,
          cryptograph,
        },
      })
      .then((response) => {
        if (response.data.success) {
          this.sendGetPasswordCode(
            {
              username,
              mobile,
            },
            function (response) {
              if (callback) {
                callback(response);
              } else {
                console.log(response);
              }
            }
          );
        } else {
          if (callback) {
            callback(response);
          } else {
            console.log(response);
          }
        }
      });
  };

  sendGetPasswordCode = ({ username, mobile }: ISendGetPasswordCode, callback: (response) => void) => {
    const { ajax } = this;
    ajax
      .request({
        url: "/web/rest/sendgetpwdcode",
        method: "get",
        headers: { apuid: getApUid() },
        params: {
          type: 0,
          destination: mobile,
          name: username,
        },
      })
      .then(function (response) {
        if (callback) {
          callback(response);
        } else {
          console.log(response);
        }
      });
  };

  setPassword = ({ name, code, password }: ISetPassword, callback: (response) => void) => {
    const { ajax } = this;
    ajax
      .request({
        url: "/web/rest/forgetPassword",
        method: "get",
        headers: { apuid: getApUid() },
        params: {
          name,
          code,
          password,
        },
      })
      .then(function (response) {
        if (callback) {
          callback(response);
        } else {
          console.log(response);
        }
      });
  };

  validateOTP = ({ userpassid, facode }) => {
    return this.ajax({
      method: "post",
      url: "/web/rest/validateotp",
      headers: { apuid: getApUid() },
      data: {
        userpassid,
        facode,
      },
    });
  };

  resendOTP = ({ mobile, username, userpassid }: IResendOtp) => {
    return this.ajax({
      method: "post",
      url: "/web/rest/sendotpcode",
      headers: { apuid: getApUid() },
      data: {
        mobile,
        username,
        userpassid,
      },
    });
  };

  register = (data: IRegisterData, callback: (response) => void) => {
    const { ajax } = this;
    const formData = new FormData();
    Object.keys(data).forEach((key, i) => {
      if (data[key]) formData.set(key, data[key]);
    });

    ajax({ method: "post", url: "/web/rest/reg", data: formData, headers: { apuid: getApUid() } })
      .then(function (response) {
        if (callback) {
          callback(response);
        } else {
          console.log(response);
        }
      })
      .catch(function (error) {
        // handle error
      });
  };
}

export default ApiAccount;
