import './lib/advertise.scss';
import './lib/alertBox.scss';
import './lib/spinWheel.scss';
import './lib/errorBox.scss';

import $script from 'scriptjs';
import Api, { IApi } from 'src/api';
import AlertBox from 'src/lib/alertBox';

import MemberSite from './lib/applyAPI';

// store jquery ready functions in jQueryQueue

const memberSiteSrc = {
  $: "https://cdnjs.cloudflare.com/ajax/libs/jquery/3.3.1/jquery.min.js",
  axios: "https://cdnjs.cloudflare.com/ajax/libs/axios/0.18.0/axios.min.js",
  QRCode: "https://cdn.jsdelivr.net/npm/qrcode@1.3.3/build/qrcode.min.js",
  jQueryUI: "https://cdnjs.cloudflare.com/ajax/libs/jqueryui/1.12.1/jquery-ui.min.js",
};
Object.assign(memberSiteSrc, window.memberSiteSrc);

// add main sources for api usage
for (let i in memberSiteSrc) {
  if (i == "$" || i == "axios" || i == "QRCode") $script(memberSiteSrc[i], i);
}

$script.ready(["$"], function () {
  $script(memberSiteSrc["jQueryUI"], "jQueryUI");
});

$script.ready(["$", "axios", "QRCode", "jQueryUI"], function () {
  // init alert box
  new AlertBox(window.$);

  // init API functions
  const api = new Api(window.axios);

  const flatApi: IApi = {
    ...api.promo,
    ...api.account,
    ...api.config,
    ...api.notice,
  };

  window.api = flatApi;

  // apply APIs in page
  new MemberSite({
    $: window.$,
    QRCode: window.QRCode,
    api: window.api,
    jQueryUI: window.jQueryUI,
  });

  // add other sources for page
  for (let i in memberSiteSrc) {
    if (i != "$" && i != "axios" && i != "QRCode" && i != "jQueryUI") $script(memberSiteSrc[i], i);
  }
});
